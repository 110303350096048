<template>
  <div class="projects_list">
      <h1>Projekte</h1>
      <p class="sellers_subline">Mit den Erlösen aus dem Onlineshop unterstützen wir folgende Projekte:</p>
      <div class="project" v-for="project in store.projects" :key="project.id" v-on:click="showProjectsDetail(project.id)">
        
      <!-- header -->
      <div class="project__header_content">
        <h4>{{formatDate(project.postDate)}}</h4>
        <h2>{{project.headline}}</h2>
      </div>
      <span class="project__content" v-html="textEllipsis(project.pageContent, 200)"></span>
      <a class="next-link">weiterlesen</a>
    </div>
  </div>
</template>

<script>
import store from '@/store/store.js';

export default {
  name: 'Projects',
  metaInfo: {
    title: 'Second Bandshirt - Projekte',
    htmlAttrs: {
      lang: 'de',
      amp: true
    }
  },
  components: {
  },
  data() {
    return {
      store,
    };
  },
  methods: {
    formatDate(dateString){
      return dateString.split("T")[0].split("-")[2]+"/"+dateString.split("T")[0].split("-")[1]+"/"+dateString.split("T")[0].split("-")[0];
    },
    showProjectsDetail(projectIndex){
      this.store.selectedProjectsIndex = projectIndex;
      this.$router.push({ path: 'projectsDetail', query: { projectId: projectIndex } })
    },
     textEllipsis(str, maxLength, { side = "end", ellipsis = "..." } = {}) {
      if (str.length > maxLength) {
        switch (side) {
          case "start":
            return ellipsis + str.slice(-(maxLength - ellipsis.length));
          case "end":
          default:
            return str.slice(0, maxLength - ellipsis.length) + ellipsis;
        }
      }
      return str;
    }
  }
}
</script>

<style lang="scss">
  
</style>
